import axios from "axios";

const apiUrl = "https://cz.axtoken.net";

export const fetchSearch = async (query, filters) => {
  try {
    const response = await axios.get(`${apiUrl}/api/search`, {
      params: {
        q: query,
        filters: filters,
      },
    });
    if (response && response.data) {
      return response.data.data;
    } else {
      throw new Error("Response data or search is undefined.");
    }
  } catch (error) {
    console.error("Error fetching search:", error);
    throw error;
  }
};
